import { BuildingWebsiteSvg } from "../../assets/svg"

const HomePage = () => {
	return (
		<div>
			<BuildingWebsiteSvg />
		</div>
	)
}

export default HomePage
